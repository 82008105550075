import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useIsLoading } from '@hooks/catalog';
import { LazyLoadImage, RetailerLogo } from '@components/Common';

interface ProductImageProps {
    image: string,
    title: string,
    promoLabel?: string,
    logo: string,
    discountLabel?: any
}

const ProductImage = ({
    image,
    title,
    promoLabel,
    logo,
    discountLabel
}: ProductImageProps) => (
    <Box>
        <LazyLoadImage src={ image } alt={ title } height={ 200 }/>
        { !useIsLoading() &&
            <Stack className="product-card-labels">
                <Stack className="product-card-banners">
                    { promoLabel &&
                        <Typography variant="caption" className="promo-label">
                            { promoLabel }
                        </Typography>
                    }

                    { discountLabel }
                </Stack>

                <RetailerLogo logo={ logo }/>
            </Stack>
        }
    </Box>
);


export default ProductImage;
