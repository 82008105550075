import { useContext, useRef } from 'react';
import { find, isEmpty, keyBy, mapValues, omitBy } from 'lodash';
import { CatalogContext, useGlobalState } from '@context';
import { useLocale, useMicrocopy } from '@hooks/common';
import {
    ContentfulGridFilter,
    IContentfulGridTile,
    IDecisionTreeAnswer,
    IDecisionTreeQuestion,
    Wizard
} from '@models';
import GridHelper from '@helpers/gridHelper';

export const useCatalogContext = () => {
    const {
        activeFilters,
        page,
        dimensions,
        sorting
    } = useContext(CatalogContext);

    return {
        activeFilters,
        page,
        dimensions,
        sorting
    };
};

export const useCatalogState = () => {
    const [ { catalogState } ] = useGlobalState();

    return catalogState;
};

export const useIsFirstRun = () => {
    const [ { catalogState } ] = useGlobalState();

    return useRef(!('prevUrl' in catalogState));
};

export const useIsLoading = () => {
    const { isLoading } = useContext(CatalogContext);

    return isLoading;
};

export const useRetailerFilters = (): ContentfulGridFilter[] => {
    const { retailerFilters } = useContext(CatalogContext);

    return retailerFilters;
};

export const useWizards = (): Wizard[] => {
    const { wizards } = useContext(CatalogContext);

    return wizards;
};

export const useActiveFilters = () => {
    const { activeFilters } = useContext(CatalogContext);

    return activeFilters;
};

export const useDimensions = () => {
    const {
        dimensions,
        setDimensions
    } = useContext(CatalogContext);

    return {
        dimensions,
        setDimensions
    };
};

export const useTotalCount = () => {
    const { totalCount } = useContext(CatalogContext);

    return totalCount;
};

export const useProductVariants = (data: { imported_id: string, product_thickness: string }[]) => omitBy(
    mapValues(keyBy(data, 'imported_id'), 'product_thickness'),
    isEmpty
);

export const useMaxDimensionsFilter = data => {
    let result = {};
    for (const item of data)
        result[item.values[0].title] = item.values[0].value;

    return result;
};

export const useMaxDimensionsFilterAPI = data => (
    {
        configurator_input_max_length: data.configurator_input_max_length,
        configurator_input_max_width: data.configurator_input_max_width
    }
);

export const useSortingValues = () => {
    const microcopy = useMicrocopy();

    return {
        [microcopy.get('catalog.sorting.relevance', 'relevance')]: '-relevance',
        [microcopy.get('catalog.sorting.price_title_low_to_high', 'price per m² ↑')]: 'price',
        [microcopy.get('catalog.sorting.price_title_high_to_low', 'price per m² ↓')]: '-price',
    };
};

export const useDefaultSortingValue = () => '-relevance';

export const useCatalogTiles = (tiles: IContentfulGridTile[] = []) => {
    const
        { activeFilters } = useContext(CatalogContext),
        filters = Object.entries(omitBy(activeFilters, filter => isEmpty(filter)));

    return tiles.filter(tile =>
        filters.length === tile.filters.length &&
        filters.every(([ key, value ]) => tile.filters.find(filterItem => filterItem[key] === value))
    );
};

export const useCatalogItemsToShow = (retailer: string, items: any, tiles: IContentfulGridTile[]) => {
    const gridItems = [...items];

    useCatalogTiles(tiles).forEach((tile: IContentfulGridTile) => {
        if (!GridHelper.getGridSettings(retailer).ignoredTiles.includes(tile.id))
            gridItems.splice(tile.position - 1, 0, tile);
    });

    return gridItems;
};

export const useRetailerQueryParams = () => {
    const { retailerQueryParams } = useContext(CatalogContext);

    return omitBy(retailerQueryParams, param => isEmpty(param));
};

export const useActiveFilterLabel = (filter: string): string => {
    const
        locale = useLocale(),
        retailerFilters = useRetailerFilters(),
        wizards = useWizards();

    return searchInTreeAnswers(wizards, filter.replace(`_${ locale }`, ''))
        || searchInRetailerFilters(retailerFilters, filter, locale)
        || filter;
};

const searchInTreeQuestion = (question: IDecisionTreeQuestion, code: string): string | null => {
    if (question.gridFilter.code === code) {
        return question.gridFilter.label;
    }

    return searchInTreeAnswers(question.answers, code);
};

const searchInTreeAnswers = (answers: (Wizard | IDecisionTreeAnswer)[], code: string): string | null => {
    for (let answer of answers) {
        if (answer.question) {
            const label = searchInTreeQuestion(answer.question, code);
            if (label) return label;
        }
    }

    return null;
};

const searchInRetailerFilters = (retailerFilters: ContentfulGridFilter[], filter: string, locale: string): string | undefined => {
    let retailerFilter: ContentfulGridFilter | undefined;
    switch (filter) {
        case 'price':
            retailerFilter = find(retailerFilters, { type: 'Price' });
            break;
        case 'product_thickness':
            retailerFilter = find(retailerFilters, { code: 'thickness' });
            break;
        case 'configurator_input_max_length':
        case 'configurator_input_max_width':
            retailerFilter = find(retailerFilters, { code: 'dimension' });
            break;
        default:
            retailerFilter = find(retailerFilters, { code: filter.replace(`_${ locale }`, '') });
            break;
    }

    return retailerFilter?.label;
};
