import ShortUniqueId from 'short-unique-id';

/**
 * Allows to check if it is a real user browser request due to "window" is not available during build process.
 * Any "window" usage must be with checking if it is a real user browser, otherwise your builds will fail.
 */
export const isBrowser = typeof window !== 'undefined';

/** Get current environment */
export const environment = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export const isProductionEnv = environment === 'production';

export const prepareLocale = (locale: string) => locale.replace('-', '_');

export const prepareUrlLocale = (locale: string) => locale.replace('_', '-');

export const checkJSON = (string: string) => {
    if (Array.isArray(string)) return string;

    try {
        return JSON.parse(string);
    } catch {
        return [ string ];
    }
};

export const GridTiles = {
    contentTile: 'ContentfulGridContentTile',
    feedbackTile: 'ContentfulGridFeedbackTile'
};

export const WizardTypes = {
    decisionTree: 'ContentfulDecisionTreeWizard'
};

export const scrollTo = (domElement: HTMLElement | null, timeout: number = 100) => {
    if (!domElement) return;

    setTimeout(() => domElement.scrollIntoView({ behavior: 'smooth' }), timeout);
};

export const getLocalStorage = () => {
    let ls: any;
    try {
        ls = require('local-storage');
    } catch (e) {
        const errorMessage = `Oops! Looks like you blocked third-party cookies and site data in your browser. Please turn it on and try again.`;
        alert(errorMessage);
        throw errorMessage;
    }

    return ls;
};

export const productLines = {
    timber: 'timber',
    decowall: 'decowall',
    tables: 'tables',
    cabinets: 'cabinets',
    insect: 'insect',
    worktops: 'worktops',
    stairs: 'stairs'
} as const;

export const addRandomQueryParam = (link: string) => {
    if (!link) return;

    const url = new URL(link);
    url.searchParams.append(
        'rnd',
        new ShortUniqueId({ length: 10, dictionary: 'hex' }).rnd()
    );

    return url.toString();
};
