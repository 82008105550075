import React, { useRef, useState } from 'react';
import { useMount } from 'react-use';
import { Box } from '@mui/material';
import { useIsMobile } from '@hooks/common';
import { RetailerComponent } from '@components';
import { TooltipDialog, TooltipPopover } from '@components/Common';
import { useFilterTooltipSkeleton } from '@hooks/skeleton/catalog';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import { trackOdinFormStep } from '@helpers';

export const useOnOutsideClick = handleOutsideClick => {
    const innerBorderRef = useRef();

    const onClick = event => {
        // @ts-ignore
        if (innerBorderRef.current && !innerBorderRef.current.contains(event.target)) {
            handleOutsideClick();
        }
    };

    useMount(() => {
        document.addEventListener("click", onClick, true);
        return () => document.removeEventListener("click", onClick, true);
    });

    return { innerBorderRef };
};

const Tooltip = ({ title, content, code, popupOnly = false }) => {
    const isMobile = useIsMobile();

    const
        [ open, setOpen ] = useState(false),
        [ dialogPosition, setDialogPosition ] = useState(0);

    const { innerBorderRef } = useOnOutsideClick(() => setOpen(false));

    const handleOpen = iconElement => {
        setOpen(!open);
        const bounding = iconElement.target.getBoundingClientRect();
        setDialogPosition(bounding.top - bounding.height);
    }

    const toolTipProps = {
        title,
        content,
        innerBorderRef,
        onClose: handleOpen
    }

    return (
        <Box className="tooltip-icon">
            <IconButton
                aria-label="info"
                sx={ { mr: -1 } }
                onClick={ element => {
                    trackOdinFormStep(
                        'toggle',
                        'filter_tooltip',
                        [],
                        { filter_name: code }
                    );
                    handleOpen(element);
                } }
            >
                <InfoIcon sx={ { width: 24, height: 24 } }/>
            </IconButton>
            { (popupOnly || isMobile) && <TooltipDialog { ...{ ...toolTipProps, ...{ top: dialogPosition, open } } }/> }
            { !popupOnly && !isMobile && open && <TooltipPopover { ...toolTipProps }/> }
        </Box>
    );
};

export default (props: {
    title: string,
    content: string,
    code?: string,
    withIcon?: boolean,
    withText?: boolean,
    popupOnly?: boolean,
}) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Common',
            name: 'Tooltip',
            element: Tooltip,
        } }
        skeleton={ useFilterTooltipSkeleton() }
    />;
