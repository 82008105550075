import React, { createContext } from 'react';
import { ContentfulRichText } from '@models';

interface PDPContextProps {
    locationState: Record<string, any>,
    product: Record<string, any>,
    retailerSettings: Record<string, any> & { discountDescription?: ContentfulRichText },
    price: number,
    setPrice: React.Dispatch<number>
}

export const defaultContext: PDPContextProps = {
    locationState: {},
    product: {},
    retailerSettings: {},
    price: 0,
    setPrice: (value: number) => {},
};
export const PDPContext = createContext(defaultContext);

export const PDPProvider = ({ data, children }) => (
    <PDPContext.Provider value={ data }>
        { children }
    </PDPContext.Provider>
);