import { createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import Rubik from '@fonts/Rubik.woff2';

const palette = {
    primary: {
        main: '#000',
        light: '#f7f7f7',
        dark: '#000'
    },
    secondary: {
        main: '#a7adb2',
    },
    yellow: '#ffe928',
};

let entrepotTheme = {
    palette,
    typography: {
        ...createFontFamily(
            'Rubik',
            {
                fontSize: 16,
                h4: {
                    fontSize: '2rem',
                },
                h5: {
                    fontSize: '1.5rem',
                },
            },
        ),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Rubik';
                  src: url(${ Rubik }) format('woff2');
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-contained': {
                        color: palette.yellow,
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.promo-label': {
                        color: palette.yellow,
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPagination-ul': {
                        'li:first-of-type, li:last-of-type': {
                            '.MuiButtonBase-root': {
                                color: palette.primary.main,
                            },
                        },
                        'li': {
                            '.MuiPaginationItem-root': {
                                fontSize: '1rem',
                                border: 0,
                                color: palette.primary.main,
                                '&:hover, &.Mui-selected': {
                                    '&:not(.MuiPaginationItem-ellipsis)': {
                                        backgroundColor: 'transparent!important',
                                        border: 0,
                                        color: palette.primary.main,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};

let theme: any = createTheme(BaseTheme, entrepotTheme);

export default createTheme(theme, ThemeComponents(theme));
