import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const checkoutApi = createApi({
    reducerPath: 'checkoutApi',
    tagTypes: [ 'Checkout', 'SawList' ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.GATSBY_MICROSITE_API_URL
    }),
    endpoints: build => ({
        placeOrder: build.mutation({
            query: ({ retailer, locale, sawListId }) => ({
                url: 'order/place',
                method: 'POST',
                body: { retailer, langCode: locale, sawListId }
            }),
            invalidatesTags: () => [ 'SawList' ]
        })
    })
});

export const {
    usePlaceOrderMutation
} = checkoutApi;