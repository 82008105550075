import { createTheme } from '@mui/material';
import { createFontFamily } from 'themes/base/base';
import NoNameTheme from './noName';
import ThemeComponents from './base/components';
import Ciutadella from '@fonts/Ciutadella-Regular.woff2';
import CiutadellaBold from '@fonts/Ciutadella-Bold.woff2';

const primaryMain = '#ffd540';
let huboNlTheme = {
    palette: {
        primary: {
            main: primaryMain,
            light: '#ebebeb',
            dark: '#a7adb2',
            darken: '#767b8d'
        },
        secondary: {
            main: '#080302'
        },
        checklist: {
            main: primaryMain,
            light: '#ebebeb',
        },
        grey: {
            100: '#e8edee'
        }
    },
    typography: {
        ...createFontFamily('Ciutadella', {
            fontSize: 14,
            h1: {
                fontSize: '2rem',
            },
            h2: {
                fontSize: '1.625rem'
            },
            h3: {
                fontSize: '1.375rem'
            },
            h4: {
                fontSize: '1.125rem'
            }
        }),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Ciutadella';
                  src: url('${ Ciutadella }') format('woff2');
                }
                @font-face {
                  font-family: 'CiutadellaBold';
                  src: url('${ CiutadellaBold }') format('woff2');
                  font-weight: bold;
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#000 !important',
                    fontWeight: 700,
                    '&.add-to-sawlist-gamma': {
                        p: {
                            color: NoNameTheme.palette.black.main
                        }
                    },
                    '&.sawlist-product-edit': {
                        fontWeight: 100,
                        '.MuiButton-endIcon': {
                            marginTop: -5
                        }
                    }
                }
            }
        },
        MuiDiv: {
            styleOverrides: {
                root: {
                    "&#microsite": {
                        padding: 0
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.sawlist-product-variant': {
                        a: {
                            display: 'flex',
                            alignItems: 'center',
                            color: NoNameTheme.palette.black.main
                        }
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '&.main-header__brand': {
                        background: NoNameTheme.palette.black.main,
                        display: 'flex',
                        flexDirection: 'row',
                        '.main-header__logo': {
                            position: 'relative',
                            color: '#000',
                            textDecoration: 'underline',
                            svg: {
                                width: 110,
                                height: 80,
                                fill: primaryMain,
                                margin: 16
                            },
                            '.sr-only': {
                                position: 'absolute',
                                width: 1,
                                height: 1,
                                padding: 0,
                                margin: -1,
                                overflow: 'hidden',
                                border: 0
                            }
                        }
                    }
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPagination-ul': {
                        'li': {
                            '.MuiPaginationItem-root': {
                                borderRadius: 0,
                                '&:hover, &.Mui-selected': {
                                    '&:not(.MuiPaginationItem-ellipsis)': {
                                        backgroundColor: `${ primaryMain } !important`,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
    }
}

let theme: any = createTheme(NoNameTheme, huboNlTheme);

export default createTheme(theme, ThemeComponents(theme));
