import { IContentfulGridContentTile } from '@models';
import { Card, CardActionArea, Stack, Typography } from '@mui/material';
import React from 'react';
import { useLocale, useNavigate, useRetailer } from '@hooks/common';
import UrlHelper, { urlPageTypes } from '@helpers/urlHelper';
import { trackGridContentTile } from '@helpers';

const ContentTile = ({ data }: { data: IContentfulGridContentTile }) => {
    const
        retailer = useRetailer(),
        locale = useLocale(),
        navigation = useNavigate();

    return (
        <Card className="grid-content-tile-card" sx={ { backgroundImage: `url(${ data.image })` } }>
            <CardActionArea
                component="a"
                onClick={ () => {
                    trackGridContentTile(
                        data.title,
                        data.label?.name,
                        `https://${ window.location.host }` + UrlHelper.getUrl(retailer, locale, urlPageTypes.ContentPage, data.contentPageUrl),
                        data.position
                    );

                    return navigation.toContentPage(data.contentPageUrl);
                } }
                sx={ { height: '100%' } }
            >
                <Stack direction="column" justifyContent="space-between">
                    { data.label.name &&
                        <Typography
                            variant="caption"
                            paragraph={ true }
                            className="grid-content-tile-card-label"
                            sx={ { backgroundColor: data.label.color } }
                        >
                            { data.label.name }
                        </Typography>
                    }
                    <Typography className="grid-content-tile-card-title">
                        { data.title }
                    </Typography>
                </Stack>
            </CardActionArea>
        </Card>
    );
};

export default ContentTile;