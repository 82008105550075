import React from 'react';
import { Box, Typography } from '@mui/material';
import { RetailerComponent } from '@components';
import { usePriceSkeleton } from '@hooks/skeleton/common';

export const getPrice = priceCents => priceCents / 100;
export const getPriceCents = price => price * 100;

export const priceSymbol = '€';

const Price = ({
    price,
    withSymbol = true,
    cents = true
}) => {
    price = cents ? price / 100 : price;

    return (
        <Box component="span" className="price">
            { withSymbol &&
                <Box
                    component="span"
                    sx={ {
                        fontSize: 14,
                        position: 'relative',
                        top: '2px',
                        pr: '2px'
                    } }
                    className="price-symbol"
                >
                    { priceSymbol }
                </Box>
            }
            { price
                ? <Typography component="span" variant="h5" sx={ { lineHeight: 1 } }>
                    { price.toFixed(2).toString().split('.')[0] },
                    <small>{ price.toFixed(2).toString().split('.')[1] }</small>
                </Typography>
                : 0
            }
        </Box>
    );
};

export default (props: {
    price: number,
    withSymbol?: boolean,
    cents?: boolean,
    showCents?: boolean
}) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Common',
            name: 'Price',
            element: Price,
        } }
        skeleton={ usePriceSkeleton() }
    />;
