import React, { createContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ThemeProvider } from '@mui/material/styles';
import Reducer from './Reducer';
import { Layout } from '@components';
import { environment, MicrocopyHelper, prepareRetailerName, retailers, SawListHelper } from '@helpers';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { useDispatch } from 'react-redux';
import { setLocale, setRetailer } from '@store/reducers/appSlice';
import {
    DefaultTheme,
    CandoTheme,
    GammaTheme,
    KarweiTheme,
    HuboTheme,
    GammaBeTheme,
    ForesteaTheme,
    PraxisTheme,
    BricoTheme,
    HornbachTheme,
    NoNameTheme,
    HuboNlTheme,
    EntrepotTheme,
    HuboInsectsTheme,
    HornbachInsectsTheme,
    PraxisInsectsTheme,
    BricoInsectsTheme,
    MrBricolageTheme,
} from '@themes';
import { Box } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';

const initialState: any = {
    site: {},
    retailer: null,
    locale: null,
    microcopy: {},
    theme: {},
    channel: null,
    catalogState: {},
    windowHeight: null,
    retailerLogo: null,
};

export const themes = {
    [retailers.Cando.name]: CandoTheme,
    [retailers.Gamma.name]: GammaTheme,
    [retailers.GammaBe.name]: GammaBeTheme,
    [retailers.Karwei.name]: KarweiTheme,
    [retailers.Hubo.name]: HuboTheme,
    [retailers.Forestea.name]: ForesteaTheme,
    [retailers.Praxis.name]: PraxisTheme,
    [retailers.Brico.name]: BricoTheme,
    [retailers.Hornbach.name]: HornbachTheme,
    [retailers.NoName.name]: NoNameTheme,
    [retailers.HuboNl.name]: HuboNlTheme,
    [retailers.Entrepot.name]: EntrepotTheme,
    // Insects
    [retailers.HuboInsects.name]: HuboInsectsTheme,
    [retailers.HornbachInsects.name]: HornbachInsectsTheme,
    [retailers.PraxisInsects.name]: PraxisInsectsTheme,
    [retailers.BricoInsects.name]: BricoInsectsTheme,
    // Stairs
    [retailers.GammaStairs.name]: GammaTheme,
    [retailers.GammaBeStairs.name]: GammaTheme,
    [retailers.KarweiStairs.name]: KarweiTheme,
    [retailers.PraxisStairs.name]: PraxisTheme,
    [retailers.BricoStairs.name]: BricoTheme,
    [retailers.HornbachStairs.name]: HornbachTheme,
    [retailers.MrBricolageStairs.name]: MrBricolageTheme,
    [retailers.HuboStairs.name]: HuboTheme,
    // Cabinets
    [retailers.CandoCabinets.name]: CandoTheme,
    [retailers.GammaCabinets.name]: GammaTheme,
    [retailers.KarweiCabinets.name]: KarweiTheme,
    [retailers.HuboCabinets.name]: HuboTheme,
    [retailers.PraxisCabinets.name]: PraxisTheme,
    [retailers.BricoCabinets.name]: BricoTheme,
    [retailers.HornbachCabinets.name]: HornbachTheme,
};

export const GlobalStateContext = createContext({});

export const GlobalStateProvider = ({ children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                ...siteMetaData
            }
            catalogMicroCopy: allContentfulResourceSet(filter: {name: {eq: "catalog"}}) {
                ...resourceNodes
            }
            pdpMicroCopy: allContentfulResourceSet(filter: {name: {eq: "pdp"}}) {
                ...resourceNodes
            }
            sawListMicroCopy: allContentfulResourceSet(filter: {name: {eq: "sawlist"}}) {
                ...resourceNodes
            }
            commonMicroCopy: allContentfulResourceSet(filter: {name: {eq: "common"}}) {
                ...resourceNodes
            }
            contentPageMicroCopy: allContentfulResourceSet(filter: {name: {eq: "content_page"}}) {
                ...resourceNodes
            }
            sampleServicePageMicroCopy: allContentfulResourceSet(filter: {name: {eq: "sample-service"}}) {
                ...resourceNodes
            }
        }
    `);

    initialState.site = data.site.siteMetadata;
    initialState.retailer = children.props.pageContext.retailer;
    initialState.channel = children.props.pageContext.channel;
    initialState.locale = children.props.pageContext.locale;
    initialState.microcopy = new MicrocopyHelper(
        data.catalogMicroCopy.nodes,
        children.props.pageContext.locale,
    ).add(data.pdpMicroCopy.nodes)
        .add(data.sawListMicroCopy.nodes)
        .add(data.commonMicroCopy.nodes)
        .add(data.contentPageMicroCopy.nodes)
        .add(data.sampleServicePageMicroCopy.nodes);
    initialState.theme = themes[prepareRetailerName(children.props.pageContext.retailer)] ?? DefaultTheme;
    initialState.retailerLogo = children.props.pageContext?.retailerLogo;

    const [ storeId ] = useQueryParam('storeId', NumberParam);

    if (!global.micrositeStoreId && storeId)
        global.micrositeStoreId = storeId;

    const [ state, dispatch ] = useReducer(Reducer, initialState);

    const [ hornBachQueryParams ]: any = useQueryParams({
        articleId: StringParam,
        storeId: StringParam,
    });

    useEffect(() => {
        if (!state.retailer) return;

        const retailer = prepareRetailerName(state.retailer);

        dispatch({ type: 'SET_THEME', theme: themes[retailer] ?? DefaultTheme });

        if (environment === 'production' && retailer.includes('Hornbach'))
            SawListHelper.checkHornbachQueryParams(retailer, hornBachQueryParams);
    }, [ state.retailer ]);

    // for migration to Redux
    const reduxDispatch = useDispatch();
    useMemo(() => {
        reduxDispatch(setRetailer(state.retailer));
        reduxDispatch(setLocale(state.locale));
    }, [ state.retailer, state.locale ]);

    const ref = useRef<HTMLDivElement>(null);

    return (
        <ReactResizeDetector
            targetRef={ ref }
            onResize={ () => dispatch({
                type: 'SET_WINDOW_HEIGHT',
                height: document.body.getBoundingClientRect().height,
            }) }
        >
            <GlobalStateContext.Provider value={ [ state, dispatch ] }>
                <ThemeProvider theme={ state.theme }>
                    <Layout { ...children.props }>
                        <Box ref={ ref }>{ children }</Box>
                    </Layout>
                </ThemeProvider>
            </GlobalStateContext.Provider>
        </ReactResizeDetector>
    );
};

const ReactResizeDetector = ({ targetRef, onResize, children }) => {
    useResizeDetector({ handleHeight: true, targetRef, onResize });

    return children;
};