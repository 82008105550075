import { createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import DaxlinePro from '@fonts/DaxPro-Regular.woff2';
import DaxlineProBold from '@fonts/DaxPro-Bold.woff2';

let candoTheme = {
    palette: {
        primary: {
            main: '#f28e00',
            light: '#f28e001a',
            dark: '#f28e00',
            darken: '#b96000'
        },
        secondary: {
            main: '#66dc80'
        },
        checklist: {
            main: '#F7901E',
            light: '#FDC689'
        },
        grey: {
            100: '#f7eddf'
        }
    },
    typography: {
        ...createFontFamily('DaxlinePro'),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'DaxlinePro';
                  src: url('${ DaxlinePro }') format('woff2');
                }
                @font-face {
                  font-family: 'DaxlinePro';
                  src: url('${ DaxlineProBold }') format('woff2');
                  font-weight: bold;
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `
        }
    }
}

let theme: any = createTheme(BaseTheme, candoTheme);

export default createTheme(theme, ThemeComponents(theme));
