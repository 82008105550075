import { configureStore } from '@reduxjs/toolkit';
import { sawListApi } from '@services/sawListApi';
import { checkoutApi } from '@services/checkoutApi';
import { environment } from '@helpers';
import appReducer from './reducers/appSlice';
import sawListReducer from './reducers/sawListSlice';

export const index = configureStore({
    reducer: {
        app: appReducer,
        sawList: sawListReducer,
        [sawListApi.reducerPath]: sawListApi.reducer,
        [checkoutApi.reducerPath]: checkoutApi.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(sawListApi.middleware)
            .concat(checkoutApi.middleware),
    devTools: environment !== 'production',
});

export type RootState = ReturnType<typeof index.getState>
export type AppDispatch = typeof index.dispatch