import React from 'react';
import { RetailerComponent } from '@components';
import { IDecisionTreeQuestion } from '@models';
import { useWizardSkeleton } from '@hooks/skeleton/catalog';

export interface WizardProps {
    onChange: any,
    tree: IDecisionTreeQuestion,
    name: string,
    prices: {
        min: number | null,
        max: number | null
    }
}

const Wizard = () => {};

export default (props: WizardProps) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Catalog',
            name: 'Wizard',
            element: Wizard,
        } }
        skeleton={ useWizardSkeleton() }
    />;
