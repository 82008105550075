import { alpha } from '@mui/material';

const ThemeComponents = theme => {
    return {
        components: {
            MuiScopedCssBaseline: {
                styleOverrides: {
                    root: {
                        a: {
                            color: theme.palette.primary.main,

                            '&:hover, &:active, &:focus, &:visited': {
                                color: theme.palette.primary.darken,
                                fontWeight: 600
                            }
                        },
                        '*': {
                            boxSizing: 'border-box'
                        },
                        '::-webkit-scrollbar' : {
                            WebkitAppearance: 'none',
                            width: '.5rem',
                        },
                        '::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            borderRadius: 5,
                            border: '2px solid #eeeeee',
                        },
                        '::-webkit-scrollbar-track': {
                            backgroundColor: '#eeeeee',
                            borderRadius: 10
                        },
                        '.header-filter': {
                            [theme.breakpoints.down('sm')]: {
                                flex: '0 1 50% !important',
                                padding: '16px 5px !important'
                            },
                            [theme.breakpoints.down('lg')]: {
                                flex: '0 1 150px',
                                wordBreak: 'break-word',
                                padding: '16px 16px'
                            },
                            cursor: 'pointer',
                            textAlign: 'center',
                            '.MuiAvatar-root': {
                                border: '1px solid transparent',
                                transition: 'border 0.1s ease',
                                transform: 'translateZ(0)'
                            },
                            'img': {
                                transform: 'scale(1)',
                                transition: 'transform 0.1s ease',
                            },
                            '&:hover, &.active': {
                                'img': {
                                    transform: 'scale(1.2)'
                                },
                                '.MuiAvatar-root': {
                                    border: `2px solid ${ theme.palette.black.main }`
                                },
                                '.MuiTypography-root': {
                                    textShadow: `0 0 1px ${ theme.palette.text.primary }`
                                }
                            }
                        },
                        '.retailer-logo': {
                            position: 'absolute',
                            maxWidth: 50,
                            [theme.breakpoints.down('sm')]: {
                                right: 4,
                                bottom: 4,
                                '&.sawlist': {
                                    maxWidth: '50px!important'
                                }
                            },
                            [theme.breakpoints.up('sm')]: {
                                right: 10,
                                bottom: 10
                            },
                            '&.pdp': {
                                position: 'relative',
                                right: 0,
                                bottom: 0,
                                maxWidth: 200,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 100,
                                },
                            },
                            '&.sawlist': {
                                maxWidth: 70
                            }
                        }
                    }
                }
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            background: 'none',
                        }
                    }
                },
                variants: [
                    {
                        props: { 'aria-label': 'info' },
                        style: {
                            color: theme.palette.primary.main
                        }
                    },
                    {
                        props: { 'aria-label': 'close' },
                        style: {
                            color: theme.palette.text.secondary
                        }
                    },
                ],
            },
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'outlined' },
                        style: {
                            fontSize: 14,
                            textTransform: 'none',
                            boxShadow: 'none',
                            borderRadius: 2, //@ts-ignore
                            borderColor: theme.palette.gray.lightTwo, //@ts-ignore
                            color: theme.palette.grey[300],
                            paddingTop: 6,
                            paddingBottom: 7,
                            '&:hover, &.active': {
                                backgroundColor: theme.palette.primary.light,
                                borderColor: theme.palette.primary.main,
                                color: theme.palette.text.primary,
                                boxShadow: 'none',
                                fontWeight: 600
                            },
                            [theme.breakpoints.down('md')]: {
                                '&.mobile-filter, & ~ .MuiFormControl-root': {
                                    width: '100%',
                                    flex: '1'
                                }
                            },
                            [theme.breakpoints.down('sm')]: {
                                '&.mobile-filter, & ~ .MuiFormControl-root': {
                                    flex: 'auto'
                                }
                            },
                            '.item-count': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: '50%', //@ts-ignore
                                color: theme.palette.white.main,
                                fontSize: 10,
                                width: 16,
                                height: 16,
                                lineHeight: '15px',
                                fontWeight: 400
                            }
                        },
                    },
                    {
                        props: { variant: 'contained' },
                        style: {
                            fontSize: 14,
                            textTransform: 'none',
                            boxShadow: 'none',
                            borderRadius: 2,
                            '&:hover': {
                                boxShadow: 'none',
                            }
                        },
                    },
                    {
                        props: { variant: 'text' },
                        style: {
                            fontSize: 18,
                            textTransform: 'none',
                            '&:hover': {
                                background: 'none'
                            }
                        },
                    },
                    {
                        props: { 'aria-label': 'show-more' },
                        style: {
                            fontSize: 18,
                            textTransform: 'none',
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginTop: -8,
                            marginBottom: -30,
                            marginLeft: -8,
                            background: 'none',
                        }
                    },
                    {
                        props: { 'aria-label': 'title-collapse' },
                        style: {
                            color: theme.palette.text.primary,
                            fontSize: 24,
                            lineHeight: 1,
                            textTransform: 'none',
                            paddingTop: 8,
                            paddingBottom: 10,
                            margin: '6px 0',
                            width: '100%',
                            justifyContent: 'flex-start',
                            textAlign: 'left'
                        }
                    },
                    {
                        props: { 'aria-label': 'sawlist' },
                        style: {
                            padding: 0,
                            minWidth: '10px',
                            color: theme.palette.text.primary,
                            width: '24px',
                            [theme.breakpoints.down('sm')]: {
                                width: '16px'
                            },
                            'span': {
                                margin: 0
                            },
                            'img': {
                                width: '100%'
                            }
                        }
                    },
                    {
                        props: { 'aria-label': 'circled-arrow' },
                        style: {
                            color: theme.palette.text.primary,
                            background: `${alpha(theme.palette.text.primary, 0.05)}`,
                            borderRadius: '50%'
                        }
                    }
                ],
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            color: theme.palette.gray.main,
                            cursor: 'not-allowed'
                        },
                        '&.close-popup': {
                            position: 'absolute',
                            right: '10px',
                            top: '17px',
                            color: theme.palette.text.secondary,
                            minWidth: '40px',
                        },
                        '&.go-to-product': {
                            height: '40px',
                            padding: '6px',
                            minWidth: '54px',
                            marginLeft: 'auto!important',
                            marginRight: '0!important',
                            marginBottom: '8px!important'
                        },
                        '&.order-overview-cta': {
                            height: 48,
                            padding: '8px',
                            width: '100%',
                            marginTop: '30px',
                            marginBottom: '8px',
                        },
                        '&.mobile-filter': {
                            height: '40px',
                        },
                        '&.add-to-sawlist-gamma': {
                            borderColor: theme.palette.primary.main,
                            height: 48,
                            '.MuiSvgIcon-root': {
                                marginRight: 5
                            },
                            '&:disabled': {
                                borderColor: theme.palette.grey[200],
                                'svg, p': {
                                    color: theme.palette.grey[200]
                                }
                            },
                        },
                    }
                },
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        '&.retailer-dialog': {
                            'a': {
                                marginTop: '5px',
                                fontSize: '0.9375rem'
                            }
                        }
                    },
                    paper: {
                        borderRadius: '5px'
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        [theme.breakpoints.down('md')]: {
                            '&[aria-label="filter-list"]': {
                                '.MuiBox-root:first-of-type .MuiDivider-root': {
                                    display: 'none'
                                }
                            }
                        },
                        '&[aria-label="pdp-text"]': {
                            paddingLeft: '30px'
                        },
                        '&[aria-label="bullets"]': {
                            paddingLeft: '30px',
                            paddingBottom: 0,
                            paddingTop: 0,
                        },
                    }
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        padding: 8,
                        marginTop: -4,
                        marginBottom: -4
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        fontSize: 18,
                        lineHeight: 1.1
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        borderRadius: 2,
                        height: 40,
                        width: '100%',
                        padding: '8px 3px',
                    },
                    label: {
                        fontSize: 12,
                        padding: 0, // @ts-ignore
                        color: theme.palette.grey[300]
                    },
                    clickable: {
                        '&:hover': {
                            border: `1px ${ theme.palette.primary.main } solid`,
                            color: theme.palette.text.primary,
                            backgroundColor: `${ theme.palette.primary.light } !important`,

                            '& .MuiChip-label': {
                                color: theme.palette.text.primary,
                                fontWeight: 'bold'
                            }
                        }
                    },
                    filled: {
                        border: `1px ${ theme.palette.primary.main } solid`,
                        backgroundColor: theme.palette.primary.light,

                        '& .MuiChip-label': {
                            color: theme.palette.text.primary,
                            fontWeight: 'bold'
                        }
                    }
                }
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        paddingTop: 12,
                        paddingBottom: 13,

                        '&:hover': {
                            '& ~ .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.primary.main,
                            }
                        },

                        [theme.breakpoints.down('md')]: {
                            paddingTop: 8,
                            paddingBottom: 9
                        },
                    },
                    root: {
                        '.MuiSvgIcon-root': {
                            position: 'absolute',
                            right: 8,
                            zIndex: 0
                        },
                        '&.Mui-focused': {
                            '.MuiSvgIcon-root': {
                                color: theme.palette.primary.main
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.primary.main,
                                borderWidth: 1,
                            }
                        },
                        [theme.breakpoints.down('md')]: {
                            borderRadius: 2,
                            width: '100%',
                            minWidth: 100,
                            '.MuiSvgIcon-root': { //@ts-ignore
                                color: theme.palette.grey[300],
                            },
                            '.MuiOutlinedInput-notchedOutline': { //@ts-ignore
                                borderColor: theme.palette.gray.lightTwo
                            }
                        },
                        '#sorting-select': {
                            zIndex: 1,
                        },
                    }
                }
            },
            MuiInputLabel: {
                variants: [
                    {
                        props: { id: 'sorting-select-label' },
                        style: {
                            lineHeight: '1.1rem',
                            '&.MuiFormLabel-filled, &.Mui-focused': {
                                marginTop: 2
                            },
                            [theme.breakpoints.down('md')]: {
                                marginTop: -4,
                                fontSize: 14, //@ts-ignore
                                color: theme.palette.grey[300],
                                '&.Mui-focused, &.MuiFormLabel-filled': {
                                    marginTop: 2,
                                    fontSize: 16,
                                }
                            },
                        }
                    }
                ]
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        'ul': {
                            marginLeft: 30,
                            padding: 0
                        },
                        'li': {
                            padding: '3x 0'
                        },
                        'p': {
                            margin: 0
                        }
                    }
                }
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.primary,
                        fontSize: theme.typography.fontSize,
                        fontWeight: 600,
                        marginLeft: 0,
                        marginRight: 0,
                        marginBottom: 3
                    }
                },
                variants: [
                    {
                        props: { 'aria-label': 'price-slider-helper-text' },
                        style: { // @ts-ignore
                            color: theme.palette.grey[300],
                        }
                    }
                ]
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        '.MuiTypography-root': {
                            color: theme.palette.text.disabled,
                            fontSize: 14
                        }
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        paddingTop: 12,
                        paddingBottom: 13,
                        '&::-webkit-outer-spin-button': {
                            WebkitAppearance: 'none'
                        },
                        '&::-webkit-inner-spin-button': {
                            WebkitAppearance: 'none'
                        },
                        '&[type="number"]': {
                            MozAppearance: 'textfield'
                        }
                    }
                }
            },
            MuiFormControl: {
                variants: [
                    {
                        props: { 'aria-label': 'price-slider-inputs' },
                        styles: {
                            padding: 20
                        }
                    }
                ],
                styleOverrides: {
                    root: {
                        '&[aria-label="price-slider-inputs"]': {
                            width: '50%'
                        }
                    }
                }
            },
            MuiSlider: {
                styleOverrides: {
                    root: {
                        height: 3,
                        '.MuiSlider-markLabel': {
                            '&[data-index="0"]': {
                                transform: 'translateX(-8px)'
                            },
                            '&[data-index="1"]': {
                                transform: 'translateX(calc(-100% + 8px))'
                            }
                        },
                    },
                    track: {
                        color: theme.palette.secondary.main,
                        height: 3
                    },
                    rail: { //@ts-ignore
                        color: theme.palette.gray.lighter,
                        height: 3
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        borderRadius: 0, // @ts-ignore
                        border: `1px ${ theme.palette.gray.main } solid`,
                        '&.product-card': {
                            height: '100%',
                            '.MuiCardActionArea-root': {
                                color: 'inherit',
                                height: '100%'
                            },
                            '.MuiCardActionArea-root:hover,.MuiCardActionArea-root:active': {
                                fontWeight: 'normal'
                            },
                            '.product-image': {
                                position: 'relative'
                            },
                            '.MuiCardContent-root': {
                                height: '100%'
                            }
                        }
                    }
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        '&:last-child': {
                            paddingBottom: '16px'
                        }
                    }
                }
            },
            MuiTypography: {
                variants: [
                    {
                        props: { variant: 'caption' },
                        style: {
                            fontSize: '1rem',
                            color: '#fff',
                            background: theme.palette.primary.main,
                            borderRadius: 4,
                            padding: '4px 8px',
                            position: 'absolute',
                            left: 16,
                            bottom: 16,
                            lineHeight: 1.2
                        },
                    },
                    {
                        props: { variant: 'body2' },
                        style: {
                            color: theme.palette.grey[300]
                        }
                    },
                    {
                        props: { 'aria-label': 'pdp-title-price' },
                        style: {
                            fontSize: 24,
                        },
                    },
                    {
                        props: { 'aria-label': 'promotion' },
                        style: {
                            padding: '2px 9px',
                            background: theme.palette.primary.main,
                            color: theme.palette.white.main,
                            borderRadius: '2px'
                        },
                    },
                    {
                        props: { 'aria-label': 'main-color' },
                        style: {
                            color: theme.palette.primary.main,
                            fontWeight: 600
                        },
                    },
                    {
                        props: { 'aria-label': 'small-text' },
                        style: {
                            fontSize: 11
                        },
                    }
                ],
                styleOverrides: {
                    root: {
                        'small': {
                            fontSize: '0.875rem'
                        },
                        '&.product-price': {
                            '.MuiTypography-h5': {
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                            },
                            '.MuiBox-root': {
                                '.MuiBox-root': {
                                    top: 0,
                                    paddingRight: 0,
                                    fontWeight: 'bold'
                                }
                            },
                        },
                        '&.total-price': {
                            '.MuiTypography-h5': {
                                fontSize: '0.9375rem',
                                fontWeight: 'bold'
                            },
                            '.MuiBox-root': {
                                '.MuiBox-root': {
                                    top: 0,
                                    paddingRight: 0
                                }
                            },
                            'small': {
                                fontSize: '0.9375rem'
                            },
                        },
                        '&.without-symbol': {
                            '.MuiTypography-h5': {
                                fontSize: '0.9375rem',
                                fontWeight: 'bold'
                            }
                        },
                        '&.end-price': {
                            '.MuiTypography-h5': {
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                            },
                            '.MuiBox-root': {
                                '.MuiBox-root': {
                                    top: '-2px',
                                    paddingRight: '3px',
                                    fontWeight: 'bold'
                                }
                            },
                        },
                        '&.error-message': {
                            position: 'absolute',
                            bottom: '-15px',
                            left: 0,
                            fontSize: 10,
                            color: theme.palette.error.main
                        },
                        [theme.breakpoints.down('sm')]: {
                            '&.error-message': {
                                fontSize: 8
                            }
                        },
                        '&.product-title': {
                            fontSize: '1.5rem',
                        },
                        '&.sawlist-desc-row-title': {
                            fontSize: 16,
                            [theme.breakpoints.down('sm')]: {
                                fontSize: 12,
                            }
                        },
                        '&.sawlist-desc-row-value': {
                            fontWeight: 'bold',
                            fontSize: 16,
                            [theme.breakpoints.down('sm')]: {
                                fontSize: 12,
                            },
                            '.sawlist-desc-row-array': {
                                margin: 0,
                                display: 'block'
                            }
                        },
                        '&.promo-label': {
                            display: 'flex',
                            alignItems: 'center',
                            '&.pdp': {
                                bottom: '130px'
                            }
                        },
                        '&.is-loading': {
                            opacity: 0.3
                        },
                        '&.pdp-discount-label': {
                            '.promo-label': {
                                position: 'unset',
                                display: 'inline-flex'
                            }
                        },
                        '&.with-discount': {
                            color: theme.palette.discount.text,
                            '.price': {
                                color: theme.palette.discount.text,
                                span: {
                                    color: theme.palette.discount.text
                                }
                            }
                        },
                    }
                }
            },
            MuiPagination: {
                styleOverrides: {
                    root: {
                        '.MuiPaginationItem-root': {
                            color: 'red',
                            borderRadius: 2,
                            height: 40,
                            width: 40,
                            lineHeight: '40px',
                            fontSize: 12, // @ts-ignore
                            color: theme.palette.grey[300],

                            '&:hover, &.Mui-selected': {
                                '&:not(.MuiPaginationItem-ellipsis)': {
                                    border: `1px ${ theme.palette.primary.main } solid`,
                                    color: theme.palette.text.primary,
                                    backgroundColor: `${ theme.palette.primary.light } !important`,
                                    fontWeight: 600
                                }
                            }
                        }
                    }
                }
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        fontSize: 15,
                        display: 'list-item',
                        listStyle: 'disc',
                    }
                }
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        [theme.breakpoints.down('md')]: {
                            '.filter-content, .catalog-block .sorting': {
                                display: 'none'
                            }
                        },
                        [theme.breakpoints.up('md')]: {
                            '.filter-mobile-skeleton': {
                                display: 'none'
                            }
                        },
                        '.MuiGrid-item': {
                            '.gallery-container': {
                                '.image-gallery-slides': {
                                    minHeight: '240px',
                                    border: `1px solid ${ theme.palette.gray.main }`
                                },
                                '.image-gallery-slide': {
                                    '.image-gallery-image': {
                                        objectFit: 'cover',
                                    }
                                },
                                '.image-gallery-thumbnails': {
                                    paddingTop: '15px',
                                    '.image-gallery-thumbnail': {
                                        border: 'none',
                                        width: '80px'
                                    },
                                    '.image-gallery-thumbnail.active': {
                                        border: 'none'
                                    },
                                    '.image-gallery-thumbnail:hover': {
                                        border: 'none',
                                        cursor: 'pointer'
                                    },
                                    '.image-gallery-thumbnail+.image-gallery-thumbnail': {
                                        marginLeft: '10px'
                                    },
                                    '.image-gallery-thumbnail-image': {
                                        objectFit: 'cover',
                                        border: `1px solid ${ theme.palette.gray.main }`
                                    }
                                }
                            },
                            'a': {
                                color: theme.palette.primary.main,
                                textDecoration: 'none'
                            },
                            '&.filter-block': {
                                [theme.breakpoints.up('md')]: {
                                    paddingTop: '71px'
                                },
                                [theme.breakpoints.up('lg')]: {
                                    paddingTop: '71px'
                                }
                            }
                        },
                        '.fixed-total': {
                            width:'100%',
                            boxShadow: `2px 0px 7px
                                        ${alpha(theme.palette.text.primary, 0.15)}`,
                            background: theme.palette.white.main,
                            padding: '16px 24px 40px 24px',
                            position: 'fixed',
                            left: 0,
                            bottom: 0
                        },
                        '.sawlist-btn': {
                            position: 'relative',
                            [theme.breakpoints.down('md')]: {
                                justifyContent: 'center'
                            },
                            '.MuiButton-root': {
                                height: '48px',
                                padding: '8px',
                                marginTop: '8px',
                                marginBottom: '40px',
                                width: '100%'
                            }
                        },
                        '&.sawlist-left': {
                            [theme.breakpoints.up('xs')]: {
                                paddingRight: 0,
                                //border: 'none'
                            },
                            [theme.breakpoints.up('lg')]: {
                                paddingRight: '32px',
                                //borderRight: `1px solid ${ alpha(theme.palette.divider, 0.5) }`
                            },
                            [theme.breakpoints.up('xl')]: {
                                paddingRight: '88px',
                                //borderRight: `1px solid ${ alpha(theme.palette.divider, 0.5) }`
                            }
                        },
                        '.filter-content': {
                            [theme.breakpoints.down('md')]: {
                                display: 'block !important',
                                position: 'absolute',
                                zIndex: 5,
                                width: 'calc(100% + 48px)',
                                height: 0,
                                marginLeft: '-24px',
                                [theme.breakpoints.down('sm')]: {
                                    width: 'calc(100% + 32px)',
                                    marginLeft: '-16px',
                                },
                                '.filter-content-wrapper': {
                                    marginTop: '16px',
                                    padding: '8px 24px',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                                    background: theme.palette.white.main,
                                }
                            },
                            '.sawlist-btn': {
                                '.MuiButton-root': {
                                    position: 'absolute',
                                    left: 0,
                                    top: '-62px'
                                }
                            }
                        },
                        '.image-zoom': {
                            '&.pdp': {
                                '.iiz__hint': {
                                    display: 'none',
                                    top: 10,
                                    [theme.breakpoints.down('lg')]: {
                                        display: 'flex'
                                    }
                                }
                            }
                        },
                        '&.grid-list': {
                            justifyContent: 'flex-start',
                            [theme.breakpoints.down('lg')]: {
                                justifyContent: 'center'
                            }
                        },
                        '.backdrop': {
                            background: theme.palette.grayscale.main,
                            marginBottom: 16,
                            padding: 16,
                            '.image-gallery-thumbnails-container': {
                                textAlign: 'start'
                            },
                            '.MuiButton-root': {
                                '&[aria-label="title-collapse"]': {
                                    fontSize: 18,
                                    fontWeight: 700
                                }
                            },
                        },
                        '&.pdp': {
                            fontSize: 16,
                            [theme.breakpoints.down('md')]: {
                                width: '100%'
                            },
                            [theme.breakpoints.up('md')]: {
                                width: '375px'
                            },
                            // [theme.breakpoints.up('lg')]: {
                            //     width: '375px'
                            // },
                            [theme.breakpoints.up('xl')]: {
                                width: '575px'
                            },
                            '.MuiTableCell-root': {
                                fontSize: 16,
                                borderBottom: '1px solid rgba(224, 224, 224, 1)'
                            },
                            '.MuiButton-root': {
                                '&[aria-label="title-collapse"]': {
                                    fontWeight: 'bold',
                                    fontSize: 20
                                }
                            },
                            '.title-collapse': {
                                '.MuiCollapse-root': {
                                    padding: 0
                                },
                                '&:not(:last-child)': {
                                    '.MuiCollapse-root': {
                                        paddingBottom: 10
                                    }
                                }
                            }
                        },
                        '&.pdp-configurator': {
                            marginBottom: 2,
                            position: 'relative',
                            flex: 1,
                            [theme.breakpoints.up('lg')]: {
                                paddingLeft: 70
                            },
                            '#configurator': {
                                transition: 'height 0.7s linear'
                            },
                            '#zoomed-image': {
                                position: 'absolute',
                                left: 0,
                                maxWidth: '100%'
                            },
                            '&.left': {
                                paddingLeft: '0!important',
                                paddingRight: 50,
                                '#zoomed-image': {
                                    right: 0,
                                    zIndex: 999,
                                }
                            }
                        },
                        '&.sawlist-grid': {
                            display: 'grid',
                            // gridTemplateColumns: 'auto 1fr',
                            gridTemplateColumns: '1fr 3.5fr',
                            gridColumnGap: 15,
                            gridRowGap: 8,
                            alignItems: 'baseline',
                            [theme.breakpoints.down('md')]: {
                                gridTemplateColumns: '1fr 2fr',
                            }
                        },
                        '&#pdp-wrapper': {
                            [theme.breakpoints.down('sm')]: {
                                flexDirection: 'column-reverse',
                            },
                            '.configurator-price': {
                                padding: '16px 0',
                                gap: '16px',
                                '&.timber': {
                                    padding: '16px 16px',
                                },
                                '&.decowall': {
                                    padding: '16px 40px',
                                },
                                '&.stairs': {
                                    padding: '16px 15px',
                                },
                                flexDirection: 'row',
                                alignItems: 'baseline',
                                justifyContent: 'space-between',
                                [theme.breakpoints.down('md')]: {
                                    padding: '0 8px!important',
                                    flexDirection: 'column',
                                },
                            },
                        },
                        '&.sawlist-right': {
                            marginTop: 12
                        }
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        textDecoration: 'none'
                    }
                }
            },
            MuiAvatar: {
                variants: [
                    {
                        props: { variant: 'rounded' },
                        style: {
                            width: '150px',
                            height: '150px',
                            borderRadius: 0
                        }
                    }
                ],
                styleOverrides: {
                    root: {
                        '&.sawlist-item-image': {
                            background: alpha(theme.palette.grey[300], 0.05),
                            img: {
                                objectFit: 'initial',
                            },
                            [theme.breakpoints.up('xs')]: {
                                width: '100%',
                                height: '100%'
                            }
                        }
                    }
                }
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        '&.product-variants': {
                            '.MuiGrid-container': {
                                '>.MuiGrid-item': {
                                    zIndex: 2,
                                    '&:nth-last-of-type(-n+1)': {
                                        marginRight: '8px',
                                        marginBottom: '8px'
                                    }
                                }
                            }
                        },
                        '&.active-filters': {
                            '.price': {
                                '>.MuiBox-root': {
                                    fontSize: 'initial',
                                    top: 0
                                },
                                '.MuiTypography-root': {
                                    fontSize: 18
                                }
                            }
                        },
                        '&.sawlist-product': {
                            marginTop: 30,
                            [theme.breakpoints.down('lg')]: {
                                maxWidth: 650,
                                margin: 'auto'
                            },
                            '>.MuiBox-root.sawlist-product-image': {
                                border: `1px solid ${ theme.palette.divider }`,
                                position: 'relative',
                                [theme.breakpoints.up('xs')]: {
                                    maxHeight: 74,
                                    maxWidth: 74,
                                    minWidth: 74,
                                    minHeight: 74
                                },
                                [theme.breakpoints.up('md')]: {
                                    maxHeight: 152,
                                    maxWidth: 152,
                                    minWidth: 152,
                                    minHeight: 152,
                                }
                            }
                        },
                        '&.sawlist-product-controls': {
                            padding: '16px 0',
                            flexGrow: 1,
                            justifyContent: 'space-between',
                            '.sawlist-product-variant': {
                                width: 200,
                                '>a': {
                                    textDecoration: 'underline'
                                },
                                svg: {
                                    marginLeft: 10,
                                    path: {
                                        stroke: theme.palette.primary.main
                                    }
                                },
                                [theme.breakpoints.down('md')]: {
                                    order: 1,
                                    width: '100%',
                                    marginTop: 20
                                }
                            },
                            [theme.breakpoints.down('md')]: {
                                alignItems: 'end',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                paddingLeft: 178,
                                margin: 0,
                                [theme.breakpoints.down('md')]: {
                                    paddingLeft: 98
                                }
                            },
                            '.quantity-controls': {
                                marginLeft: -190,
                                [theme.breakpoints.down('md')]: {
                                    margin: 0
                                },
                                '.remove-sawlist-item': {
                                    svg: {
                                        path: {
                                            stroke: theme.palette.primary.main
                                        }
                                    },
                                }
                            }
                        },
                        '&.sawlist-product-content': {
                            width: '100%',
                            '.sawlist-product-edit': {
                                fontSize: '16px',
                                color: theme.palette.primary.main,
                                svg: {
                                    stroke: theme.palette.primary.main
                                },
                                minWidth: 100,
                                textDecoration: 'underline',
                                justifyContent: 'flex-end',
                                '.MuiButton-endIcon': {
                                    marginLeft: 7
                                },
                                '&:hover': {
                                    fontWeight: 600
                                }
                            }
                        },
                        '&.sawlist-product-title': {
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            h5: {
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '1.2rem'
                                }
                            }
                        },
                        '.tooltip-icon': {
                            position: 'relative',
                            '.tooltip-popover': {
                                position: 'absolute',
                                zIndex: 1000,
                                width: 500,
                                background: theme.palette.white.main,
                                borderRadius: 15,
                                border: `1px solid ${ theme.palette.gray.light }`,
                                top: -30,
                                right: -25,
                                left: 'auto',
                                transform: 'translateX(100%)',
                                padding: 20,
                                boxShadow: `0 2px 3px 0 ${ theme.palette.gray.lightTwo }`,
                                '.tooltip-popover-title': {
                                    fontSize: '1.25rem',
                                    fontWeight: 700,
                                    marginBottom: 10
                                },
                                '.tooltip-popover-content': {
                                    maxHeight: 400,
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    'p, ul': {
                                        marginTop: 0
                                    }
                                },
                                '.tooltip-popover-close': {
                                    position: 'absolute',
                                    right: 10,
                                    top: 15,
                                }
                            },
                            '.tooltip-popover:after, .tooltip-popover:before': {
                                right: '100%',
                                top: 50,
                                border: 'solid transparent',
                                content: '" "',
                                height: 0,
                                width: 0,
                                position: 'absolute',
                                pointerEvents: 'none',
                                boxSizing: 'border-box'
                            },
                            [theme.breakpoints.up('md')]: {
                                '.tooltip-popover:before': {
                                    borderColor: 'transparent',
                                    borderRightColor: theme.palette.gray.lightTwo,
                                    borderWidth: 16,
                                    marginTop: -16
                                },
                                '.tooltip-popover:after': {
                                    borderColor: 'transparent',
                                    borderRightColor: theme.palette.white.main,
                                    borderWidth: 15,
                                    marginTop: -15
                                }
                            },
                        },
                        '&.quantity-wrapper': {
                            padding: 0,
                            borderRadius: 5,
                            border: `1px solid ${ theme.palette.grey[400] }`,
                            marginRight: '18px',
                            [theme.breakpoints.down('sm')]: {
                                marginLeft: 0,
                            },
                            '.MuiButtonBase-root': {
                                color: theme.palette.black.main,
                                width: '36px',
                                height: '36px',
                                '&:disabled': {
                                    color: theme.palette.grey[200]
                                }
                            },
                            '.MuiTypography-root': {
                                fontSize: 16,
                                width: '36px',
                                height: '36px',
                                margin: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: `1px solid ${ theme.palette.grey[400] }`,
                                borderRight: `1px solid ${ theme.palette.grey[400] }`
                            }
                        },
                        '.filter-title': {
                            color: 'initial'
                        },
                        '&.header-filters': {
                            justifyContent: 'center',
                            background: theme.palette.primary.light,
                            '>.MuiStack-root': {
                                overflow: 'auto',
                                [theme.breakpoints.down('lg')]: {
                                    padding: '0 16px',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center'
                                },
                                '.header-filter': {
                                    flex: 1,
                                }
                            }
                        },
                        '.catalog-product-price': {
                            '.go-to-product': {
                                margin: 'initial!important',
                                marginLeft: 'initial!important',
                                marginRight: 'initial!important',
                                marginBottom: 'initial!important'
                            }
                        },
                        '.delivery-info': {
                            marginTop: 16,
                            '.delivery-icon': {
                                color: theme.palette.icons.usp,
                                marginRight: 4,
                                width: 22,
                                height: 22,
                                flexShrink: 0
                            },
                            p: {
                                fontSize: '0.85rem'
                            }
                        },
                        '.service-info': {
                            color: '#516371',
                            marginTop: 16,
                            '.service-icon': {
                                marginRight: 4,
                                width: 22,
                                height: 22,
                                flexShrink: 0,
                                path: {
                                    stroke: theme.palette.primary.main
                                }
                            },
                            'a,p': {
                                fontSize: '0.85rem'
                            },
                            a: {
                                textDecoration: 'underline',
                                '&:hover,p:hover': {
                                    fontWeight: 600
                                }
                            }
                        },
                        '.combined-price': {
                            '.price': {
                                fontSize: 28,
                                fontWeight: 700,
                                span: {
                                    fontSize: 28,
                                    fontWeight: 700,
                                }
                            }
                        },
                        '&.sawlist-product-usp': {
                            '.MuiDivider-root': {
                                [theme.breakpoints.up('md')]: {
                                    display: 'inline-block',
                                    margin: '0 10px',
                                    height: '75%',
                                    color: '#A1B5C5'
                                }
                            },
                            marginBottom: 14,
                            '.delivery-info': {
                                marginTop: 10,
                                [theme.breakpoints.down('md')]: {
                                    '.delivery-icon': {
                                        width: 18
                                    }
                                }
                            },
                            '.service-info': {
                                marginTop: 10,
                                [theme.breakpoints.down('md')]: {
                                    '.service-icon': {
                                        width: 18
                                    }
                                }
                            }
                        },
                        '&.order-overview': {
                            height: '100%',
                            border: `1px solid ${ theme.palette.grey[200] }`,
                            borderRadius: 5,
                            padding: 8 * 3,
                            '.discount-info': {
                                textAlign: 'center',
                                fontSize: '0.9rem',
                                marginBottom: 0
                            },
                            '.order-overview-top': {
                                '.order-overview-cta': {
                                    marginTop: 16
                                },
                                '.delivery-icon': {
                                    color: theme.palette.icons.usp,
                                    marginRight: 4,
                                    width: 22,
                                },
                                'p': {
                                    margin: 0,
                                    padding: 0,
                                    fontSize: '0.9rem'
                                }
                            },
                            '.order-overview-description': {
                                marginTop: 10,
                            }
                        },
                        '&.order-overview-mobile': {
                            margin: '0 auto',
                            maxWidth: 650,
                            padding: '32px 24px 60px 24px',
                            '.order-overview': {
                                border: 0,
                                borderRadius: 0,
                                padding: 0
                            }
                        },
                        '&.full-width-pdp': {
                            '.pdp-content': {
                                position: 'relative',
                                [theme.breakpoints.down('lg')]: {
                                    marginTop: -32,
                                    paddingTop: 0
                                }
                            },
                            '#zoomed-image': {
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                zIndex: 999
                            },
                            '.specs-table-left-cell': {
                                [theme.breakpoints.up('md')]: {
                                    maxWidth: '30px',
                                    overflowWrap: 'world-break'
                                }
                            }
                        },
                        '&.product-card-labels': {
                            position: 'absolute',
                            width: '100%',
                            bottom: 12,
                            flexDirection: 'row',
                            padding: '0 16px',
                            '.product-card-banners': {
                                flexDirection: 'column',
                                alignItems: 'baseline',
                                width: '75%',
                                '.promo-label': {
                                    position: 'initial',
                                    margin: '4px 0'
                                }
                            }
                        }
                    }
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    root: {

                        '.MuiPaper-root': {
                            borderRadius: '45px 45px 0 0'
                        },
                        '.sawlist-qr-payment': {
                            maxWidth: 800,
                            margin: 'auto',
                            '.sawlist-qr-payment-back-button': {
                                padding: '0 45px',
                                svg: {
                                    width: '0.5em',
                                    height: '0.5em'
                                }
                            },
                            '.sawlist-qr-payment-content': {
                                padding: '45px',
                                paddingTop: 0,
                                '.sawlist-qr-payment-title': {
                                    width: '90%',
                                    maxWidth: 500,
                                    color: '#413f33',
                                    fontWeight: 700,
                                    fontSize: '2rem'
                                },
                                '.sawlist-qr-payment-description': {
                                    width: '90%',
                                    maxWidth: 500,
                                    marginTop: '28px',
                                    color: '#696f6a',
                                    fontSize: '24px'
                                },
                                '.sawlist-qr-payment-code': {
                                    display: 'flex',
                                    svg: {
                                        marginTop: 10,
                                        [theme.breakpoints.down('sm')]: {
                                            height: 'auto',
                                            width: '100%',
                                            maxWidth: '100%'
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            MuiSkeleton: {
                styleOverrides: {
                    root: {
                        '&.quantity-skeleton': {
                            marginTop: '-16px',
                            [theme.breakpoints.down('sm')]: {
                                marginLeft: 0
                            }
                        }
                    }
                }
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        '.filter-tooltip-dialog-content': {
                            margin: 32,
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            '.filter-tooltip-dialog-title': {
                                minWidth: 300,
                                padding: '16px 24px',
                                fontSize: '1.25rem',
                                lineHeight: '1.6'
                            },
                            '.filter-tooltip-dialog-description': {
                                padding: '20px 24px',
                                paddingTop: 0,
                                maxHeight: 500,
                                overflow: 'auto'
                            },
                            '.filter-tooltip-close-btn': {
                                position: 'absolute',
                                right: 10,
                                top: 10
                            }
                        }
                    }
                }
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        '&.content-page': {
                            '.content-page-title': {
                                marginBottom: 14,
                                fontSize: 42,
                                fontWeight: 700,
                                lineHeight: '33px'
                            },
                            '.content-page-container': {
                                '&.preview.show-controls': {
                                    border: '1px solid transparent',
                                    '&:hover': {
                                        border: '1px dashed gray',
                                        cursor: 'pointer',
                                        '>.preview-edit-btn': {
                                            display: 'flex',
                                            top: -36
                                        }
                                    },
                                    '.preview-edit-btn': {
                                        display: 'none',
                                        position: 'absolute',
                                        right: 0,
                                        zIndex: 999,
                                        background: 'none',
                                        color: theme.palette.primary.main,
                                        svg: {
                                            stroke: theme.palette.primary.main
                                        },
                                        '&:hover': {
                                            background: 'rgba(0, 0, 0, 0.04)'
                                        }
                                    },
                                    [[ '.content-page-paragraph',
                                        '.content-page-card',
                                        '.content-page-checklist-item',
                                        '.content-page-image',
                                        '.content-page-accordion .content-page-accordion-item'
                                    ].join(',')]: {
                                        position: 'relative',
                                        border: '1px solid transparent',
                                        '&:hover': {
                                            border: `1px dashed ${ theme.palette.primary.main }`,
                                            cursor: 'pointer',
                                            '>.preview-edit-btn': {
                                                display: 'flex'
                                            }
                                        },
                                    }
                                },
                                '&:last-child': {
                                    marginBottom: 0
                                },
                                position: 'relative',
                                marginBottom: 75,
                                width: 'calc(100% + 20px)',
                                marginLeft: '-20px',
                                [theme.breakpoints.down('md')]: {
                                    marginBottom: 35,
                                },
                                '.content-page-container-title': {
                                    fontSize: 30,
                                    lineHeight: '33px',
                                    fontWeight: 700,
                                    paddingBottom: 20
                                },
                                '.content-page-paragraph': {
                                    '.content-page-paragraph-title': {
                                        fontSize: 26,
                                        fontWeight: 700,
                                        lineHeight: '33px'
                                    },
                                    '.content-page-paragraph-label': {
                                        marginTop: 5,
                                        marginBottom: -10,
                                        fontSize: 16,
                                        lineHeight: '29px',
                                        fontWeight: 700,
                                        color: theme.palette.primary.main
                                    },
                                    '.MuiButton-root': {
                                        color: theme.palette.white.main,
                                        borderRadius: 1000,
                                        fontSize: 16,
                                        lineHeight: '29px',
                                        fontWeight: 700,
                                        padding: '10px 20px',
                                        marginTop: 8
                                    }
                                },
                                '.content-page-card': {
                                    position: 'relative',
                                    paddingBottom: 'calc(25% - 20px)',
                                    [theme.breakpoints.down('md')]: {
                                        paddingBottom: 'calc(100% - 20px)'
                                    },
                                    '.MuiCard-root': {
                                        position: 'absolute',
                                        height: '100%',
                                        maxHeight: '310px',
                                        border: 0,
                                        [theme.breakpoints.down('md')]: {
                                            maxHeight: 'none'
                                        },
                                        '.MuiCardContent-root': {
                                            padding: '25px',
                                            backgroundSize: 'cover',
                                            height: '100%',
                                            '.content-page-card-title': {
                                                color: theme.palette.white.main,
                                                fontSize: 14
                                            },
                                            '.content-page-card-description': {
                                                color: theme.palette.white.main,
                                                fontSize: 16,
                                                fontWeight: 700
                                            },
                                            '.MuiIconButton-root': {
                                                padding: 0,
                                                marginLeft: 30,
                                                '.MuiSvgIcon-root': {
                                                    color: theme.palette.white.main,
                                                    width: 50,
                                                    height: 50,
                                                    '&:hover': {
                                                        color: 'inherit'
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                '.content-page-checklist-item': {
                                    '.MuiIconButton-root': {
                                        background: theme.palette.checklist.light,
                                        color: theme.palette.checklist.main,
                                    },
                                    '.content-page-checklist-item-title': {
                                        fontSize: 21,
                                        fontWeight: 700,
                                        lineHeight: '23px',
                                        paddingTop: 25,
                                        paddingBottom: 15
                                    }
                                },
                                '.content-page-image': {
                                    '.gatsby-image-wrapper': {
                                        width: '100%',
                                        maxWidth: '100%'
                                    }
                                },
                                '.content-page-accordion': {
                                    '.content-page-accordion-item': {
                                        '&:hover': {
                                            '.preview-edit-btn': {
                                                display: 'flex'
                                            }
                                        },
                                        marginBottom: 15,
                                        '&:last-child': {
                                            marginBottom: 0
                                        },
                                        '.MuiAccordionSummary-content': {
                                            flexDirection: 'column'
                                        },
                                        '.content-page-accordion-label': {
                                            color: theme.palette.primary.main,
                                            fontSize: 16,
                                            lineHeight: '29px',
                                            fontWeight: 400,
                                            marginBottom: 10
                                        },
                                        '.content-page-accordion-title': {
                                            fontSize: 22,
                                            lineHeight: '25px',
                                            fontWeight: 700,
                                            maxWidth: 500
                                        },
                                        '.MuiAccordionDetails-root': {
                                            'p:first-of-type': {
                                                marginTop: 0
                                            }
                                        },
                                        '.MuiButtonBase-root': {
                                            '&[aria-label="show-more"]': {
                                                fontSize: 16,
                                                lineHeight: '29px',
                                                marginBottom: -10
                                            }
                                        },
                                        '.content-page-accordion-collapse:not(.MuiCollapse-entered)': {
                                            '&.truncated': {
                                                '&:after': {
                                                    content: '" "',
                                                    position: 'absolute',
                                                    display: 'block',
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    height: '50%',
                                                    background: 'linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(255 255 255) 70%)'
                                                }
                                            }
                                        }
                                    },
                                }
                            }
                        },
                        '&.product-page': {
                            '.MuiDivider-root': {
                                '&[aria-label="title-collapse-divider"]': {
                                    display: 'initial!important',
                                    marginTop: -5,
                                    marginBottom: 10,
                                    borderColor: theme.palette.black.main
                                }
                            }
                        },
                        '&.sample-service-page': {
                            color: theme.palette.gray.dark,
                            'h1,h2,h3': {
                                color: theme.palette.black.main,
                                fontSize: '2.75rem',
                                fontWeight: 700,
                                marginBottom: '2rem',
                            },
                            h2: {
                                fontSize: '2.25rem',
                                margin: '1.25rem 0',
                            },
                            h3: {
                                fontSize: '1.5rem',
                                margin: '1.25rem 0',
                            },
                            '.sample-groups': {
                                '.sample': {
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    opacity: 0.5,
                                    cursor: 'not-allowed',
                                    '&:hover': {
                                        border: '1px solid #ccc',
                                        margin: -1,
                                    },
                                    '&.selectable': {
                                        cursor: 'pointer',
                                        opacity: 1,
                                    },
                                    '&.selected': {
                                        border: '1px solid',
                                        borderColor: theme.palette.primary.main,
                                        '&::before': {
                                            content: `'\\2714\\0020'`,
                                            position: 'absolute',
                                            zIndex: 10,
                                            top: -10,
                                            width: 40,
                                            height: 40,
                                            background: theme.palette.primary.main,
                                            color: theme.palette.white.main,
                                            lineHeight: '40px',
                                            borderRadius: 40,
                                            textAlign: 'center',
                                        },
                                    },
                                    '.gatsby-image-wrapper': {
                                        width: '100%',
                                        height: 155,
                                    },
                                    p: {
                                        color: theme.palette.black.main,
                                        fontSize: '0.9rem',
                                    },
                                },
                            },
                            '.samples-selected': {
                                fontStyle: 'italic',
                                color: theme.palette.black.main,
                            },
                            '.MuiFormLabel-root': {
                                marginBottom: 10,
                                paddingBottom: 3,
                                '&.Mui-required': {
                                    '.MuiFormLabel-asterisk': {
                                        color: theme.palette.error.main
                                    }
                                }
                            },
                            '.sample-contact-form-text-field': {
                                maxWidth: 365,
                                width: '100%',
                            },
                            '.sample-service-page-button': {
                                marginTop: 20,
                                minWidth: 250,
                                maxWidth: 365,
                                height: 50,
                                fontSize: 18,
                                padding: '0 15px',
                                fontWeight: 700,
                                letterSpacing: '.05rem',
                                color: theme.palette.white.main,
                                backgroundColor: theme.palette?.cta?.main ?? theme.palette.primary,
                                '&.Mui-disabled': {
                                    backgroundColor: theme.palette.gray.main,
                                    color: theme.palette.gray.dark
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: '100%',
                                },
                            },
                        },
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        [theme.breakpoints.down('md')]: {
                            '.MuiToolbar-root': {
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                a: {
                                    marginLeft: 0
                                },
                                '.preview-controls': {
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start',
                                    '>label,>div': {
                                        marginTop: 10
                                    }
                                }
                            }
                        }
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        '&.grid-content-tile-card': {
                            minHeight: 378,
                            height: '100%',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            '.grid-content-tile-card-label': {
                                fontSize: 16,
                                fontWeight: 700,
                                padding: '10px 20px',
                                borderRadius: 15,
                                width: 'fit-content',
                                marginLeft: 10,
                                marginTop: 15,
                                marginBottom: -5,
                                position: 'relative',
                                bottom: 'auto',
                                left: 'auto',
                                letterSpacing: 0.5
                            },
                            '.grid-content-tile-card-title': {
                                color: theme.palette.white.main,
                                fontSize: 26,
                                marginTop: 10,
                                padding: 10,
                                lineHeight: 1,
                                letterSpacing: 0.5
                            }
                        },
                        '&.grid-feedback-tile': {
                            height: '100%',
                            border: `1px solid ${theme.palette.gray.main}`,
                            backgroundColor: theme.palette.tile.main,
                            '.MuiCardContent-root, .MuiStack-root': {
                                height: '100%'
                            },
                            '.grid-feedback-tile-title': {
                                fontSize: 36,
                                fontWeight: 700,
                                lineHeight: '46px',
                                color: theme.palette.tile.text
                            },
                            '.grid-feedback-tile-subtitle': {
                                color: theme.palette.tile.text,
                                fontSize: 14,
                                lineHeight: '18px',
                                fontWeight: 400,
                                marginBottom: 18,
                                marginTop: 10
                            },
                            '.grid-feedback-tile-confirm-button': {
                                marginBottom: 8,
                                minHeight: 40
                            },
                            '.grid-feedback-tile-result-icon': {
                                color: theme.palette.tile.success
                            },
                            '.grid-feedback-tile-result': {
                                paddingTop: 10,
                                color: theme.palette.tile.text,
                                fontSize: 24,
                                fontWeight: 700,
                                lineHeight: '30px',
                                textAlign: 'center'
                            },
                            '.grid-feedback-tile-input': {
                                color: theme.palette.tile.text,
                                background: theme.palette.tile.input,
                                margin: '10px 0',
                                'label,textarea': {
                                    padding: '0 10px'
                                }
                            }
                        },
                        '&.tooltip-container': {
                            overflow: 'visible',
                            '.retailer-logo': {
                                display: 'none'
                            }
                        }
                    }
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        '&.pdp-discount-accordion': {
                            marginTop: 4,
                            border: 0,
                            backgroundColor: 'transparent',
                            '&:before': {
                                height: 0
                            },
                            '.MuiAccordionSummary-root': {
                                minHeight: 32,
                                background: theme.palette.discount.label,
                                borderRadius: 4,
                                display: 'inline-flex',
                                padding: '0 4px',
                                '&.Mui-expanded': {
                                    display: 'flex',
                                    borderRadius: '4px 4px 0 0',
                                },
                                '.promo-label': {
                                    position: 'static',
                                    width: '100%',
                                    height: '100%',
                                    paddingLeft: 4,
                                    fontSize: 18,
                                    background: theme.palette.discount.label
                                },
                                '.MuiAccordionSummary-expandIconWrapper svg': {
                                    fill: theme.palette.white.main
                                },
                                '.MuiAccordionSummary-content': {
                                    margin: 0
                                }
                            },
                            '.MuiAccordionDetails-root': {
                                border: `1px solid ${ theme.palette.discount.label }`,
                                borderBottomLeftRadius: 5,
                                borderBottomRightRadius: 5,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingBottom: 10,
                                p: {
                                    '&:first-of-type': {
                                        marginTop: 0
                                    },
                                    '&:last-of-type': {
                                        marginBottom: 0
                                    }
                                }
                            }
                        }
                    }
                }
            },
            MuiStepper: {
                styleOverrides: {
                    root: {
                        '.progress-stepper-label': {
                            '.MuiStepLabel-alternativeLabel': {
                                maxWidth: 100
                            }
                        }
                    }
                }
            }
        }
    };
};

export default ThemeComponents;
