import { alpha, createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import RobotoCondensed from '@fonts/RobotoCondensed-Regular.woff2';
import RobotoCondensedBold from '@fonts/RobotoCondensed-Bold.woff2';

let palette = {
    primary: {
        main: '#001D85',
        light: '#F8F9FC',
        dark: '#f28e00',
        darken: '#b96000',
        lightBlue: '#2E4EBE',
    },
    secondary: {
        main: '#66dc80'
    },
    text: {
        primary: '#1F2747',
        secondary: '#3c3c3c',
        disabled: '#a8b3bf'
    },
    gray: {
        main: '#ccc',
        light: '#c4c4c4',
        lightOne: '#cfcecf',
        lightTwo: '#bbb',
        dark: '#71767a',
    },
    grey: {
        300: '#71767a'
    },
    white: {
        main: '#fff'
    },
    blue: {
        main: '#d8dadf',
        light: '#505971',
        lightOne: '#2E4EBE',
        lightTwo: '#007AFF',
        lightThree: '#505971'
    },
    red: {
        main: '#D33641'
    },
    green: {
        main: '#669933'
    },
    black: {
        main: '#000'
    },
    divider: '#c4c4c4',
    tonalOffset: 0.1,
    checklist: {
        main: '#fff',
        light: '#001D85'
    },
    grayscale: {
        main: '#fafafa'
    },
    wizard: {
        dark: '#669933'
    }
}

let theme: any = createTheme({
    palette,
    typography: {
        ...createFontFamily('RobotoCondensed', {
            fontSize: 14,
        }),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 423,
            md: 850,
            lg: 991,
            xl: 1368,
        },
    }
});

theme = createTheme(theme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'RobotoCondensed';
                  src: url('${ RobotoCondensed }') format('woff2');
                }
                @font-face {
                  font-family: 'RobotoCondensed';
                  src: url('${ RobotoCondensedBold }') format('woff2');
                  font-weight: bold;
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '.MuiButton-root.MuiButton-contained': {
                        '&:hover': {
                            background: theme.palette.primary.main
                        }
                    },
                    '.MuiButtonBase-root.MuiChip-root': {
                        '&:hover': {
                            border: `1px solid ${theme.palette.blue.lightOne}`,
                            backgroundColor: `${ alpha(theme.palette.blue.lightOne, 0.1) } !important`,
                            '.MuiChip-label': {
                                color: theme.palette.blue.lightOne
                            }
                        },
                        '.MuiChip-filled': {
                            border: `1px solid ${theme.palette.blue.lightOne}`,
                            backgroundColor: `${ alpha(theme.palette.blue.lightOne, 0.1) } !important`,
                            '.MuiChip-label': {
                                color: theme.palette.blue.lightOne
                            }
                        }
                    },
                    '.fixed-total': {
                        '.without-symbol': {
                            '.price': {
                                '.MuiTypography-root': {
                                    color: theme.palette.blue.lightThree
                                }
                            }
                        }
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.header-title': {
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        fontSize: '2.250rem',
                        '~.MuiTypography-root': {
                            textAlign: 'center'
                        }
                    },
                    '.price': {
                        '.MuiBox-root': {
                            display: 'none',
                        },
                        '.MuiTypography-root': {
                            fontWeight: 'bold',
                            color: theme.palette.red.main
                        }
                    },
                    '&.total-price': {
                        '.price': {
                            '.MuiTypography-root': {
                                fontWeight: 'bold',
                                color: theme.palette.text.primary
                            }
                        }
                    },
                    '&.sawlist-title': {
                        color: theme.palette.primary.main
                    },
                    '&.popup-title': {
                        marginBottom: '20px'
                    },
                    '&.promo-label': {
                        backgroundColor: theme.palette.green.main
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '&.usp-object': {
                        '.MuiSvgIcon-root': {
                            width: '1.3em',
                            height: '1.3em'
                        }
                    },
                    '.price-slider': {
                        width: '90%',
                        margin: '0 auto',
                        marginTop: '24px',
                        '.MuiSlider-root': {
                            color: theme.palette.gray.lightOne,
                            '.MuiSlider-thumb': {
                                background: theme.palette.white.main,
                                border: `2px solid ${theme.palette.primary.main}`,
                                width: '24px',
                                height: '24px'
                            }
                        },
                        '.MuiSlider-track': {
                            color: theme.palette.primary.main
                        },
                        '.MuiSlider-rail': {
                            background: theme.palette.gray.lightOne
                        },
                        '.MuiSlider-mark': {
                            backgroundColor: theme.palette.gray.lightOne
                        },
                        '.MuiSlider-markLabel': {
                            '&[data-index="0"]': {
                                transform: 'translateX(-24px)'
                            },
                            '&[data-index="1"]': {
                                transform: 'translateX(calc(-100% + 24px))'
                            },
                            '.price': {
                                '.MuiTypography-root': {
                                    fontSize: 14,
                                },
                                '.MuiBox-root': {
                                    top: 0
                                }
                            }
                        }
                    },
                    '&.confirm-dialog': {
                        width: '100%',
                        padding: '16px 12px',
                        '.MuiButtonBase-root': {
                            background: 'none',
                            height: '48px',
                            fontSize: 14,
                            color: '#505971',
                            '&:first-of-type': {
                                background: theme.palette.red.main,
                                color: theme.palette.white.main
                            }
                        }
                    },
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPagination-ul': {
                        'li': {
                            '.MuiPaginationItem-root': {
                                '&:hover, &.Mui-selected': {
                                    '&:not(.MuiPaginationItem-ellipsis)': {
                                        background: `${alpha(theme.palette.primary.lightBlue, 0.1)}`,
                                        border: `1px solid theme.palette.primary.lightBlue`,
                                        borderRadius: '2px',
                                        color: theme.palette.text.primary
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&[aria-label="title-collapse"]': {
                        color: theme.palette.primary.main
                    },
                    '&[aria-label="show-more"]': {
                        color: theme.palette.blue.lightOne
                    },
                    '&.order-overview-cta, &.order-overview-cta-mobile': {
                        background: theme.palette.green.main,
                        '&.MuiButton-contained': {
                            '&:hover': {
                                background: `${theme.palette.green.main} !important`,
                            }
                        },
                        '&.Mui-disabled': {
                            background: `${ alpha(theme.palette.black.main, 0.1) }`,
                        }
                    },
                    '&.content-page-paragraph-button': {
                        borderRadius: '6px !important',
                        background: theme.palette.red.main
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: theme.palette.blue.lightTwo
                    }
                }
            }
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    '.active-filters': {
                        color: theme.palette.primary.lightBlue,
                        '.MuiButtonBase-root': {
                            '&[aria-label="close"]': {
                                color: theme.palette.primary.lightBlue
                            }
                        }
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&[aria-label="price-slider-inputs"]': {
                        '.MuiFormHelperText-root': {
                            fontWeight: 400
                        },
                        '.MuiInputBase-root': {
                            fontWeight: 'bold',
                            '.MuiInputAdornment-root': {
                                display: 'none'
                            }
                        }
                    }
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    '&.MuiContainer-maxWidthXl': {
                        [theme.breakpoints.up('md')]: {
                            paddingLeft: 0,
                            paddingRight: 0
                        }
                    }
                }
            }
        }
    }
});

theme = createTheme(BaseTheme, theme);

export default createTheme(theme, ThemeComponents(theme));
