import React, { createContext } from 'react';
import { IProduct } from '@models';

export const defaultSawListItemContext: IProduct = {
    id: '',
    image: '',
    options: {},
    productInfo: {},
    quantity: 0,
    quotation: '',
    quotationId: 0,
    title: '',
    type: '',
    referenceId: '',
    priceInclVat: 0,
    priceExclVat: 0,
    discount: 0,
    vat: 0,
    mapping: {
        title: '',
        rows: []
    },
};
export const SawListItemContext = createContext(defaultSawListItemContext);

export const SawListItemProvider = ({ data, children }) => (
    <SawListItemContext.Provider value={ data }>
        { children }
    </SawListItemContext.Provider>
);