import React from 'react';
import { Box, Card, CardActionArea, CardContent, Stack } from '@mui/material';
import { MultiLanguageHelper } from '@helpers';
import { useLocale, useNavigate } from '@hooks/common';
import { GoToProductButton, ProductVariants, ProductHeader, ProductImage } from '@components/Catalog';
import { useIsLoading, useRetailerQueryParams } from '@hooks/catalog';
import { ProductPrice, ProductUSP, DiscountLabel } from '@components/Common';
import { usePriceSkeleton } from '@hooks/skeleton/common';
import { useProductName } from '@hooks/productPage';

const Product = ({ data, thickness_variants }) => {
    const
        locale = useLocale(),
        navigation = useNavigate(),
        ML = new MultiLanguageHelper(data, locale),
        retailerQueryParams = useRetailerQueryParams(),
        { title, subtitle } = useProductName(data);

    return (
        <Card className="product-card">
            <CardActionArea
                component="div"
                onClick={ () => navigation.toProduct(data.url, retailerQueryParams) }
            >
                <Stack direction="column" height="100%">
                    <Box className="product-image">
                        <ProductImage
                            image={ data.cdn?.plp }
                            title={ title }
                            promoLabel={ ML.getField('promo_label') }
                            logo={ data.product_logo_plp }
                            discountLabel={ <DiscountLabel discount={ data.discount }/> }
                        />
                    </Box>
                    <CardContent>
                        <Stack direction="column" justifyContent="space-between" height="100%">
                            <ProductHeader
                                subtitle={ subtitle }
                                title={ title }
                                length={ data.configurator_input_max_length / 10 }
                                width={ data.configurator_input_max_width / 10 }
                            />

                            <Stack direction="column">
                                <ProductVariants variants={ thickness_variants }/>

                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mt={ 2 }
                                    className="catalog-product-price"
                                >
                                    { useIsLoading()
                                        ? usePriceSkeleton()
                                        : <ProductPrice
                                            price={ data.price }
                                            discount={ data.discount }
                                            productLine={ data.product_line }
                                        />
                                    }

                                    <GoToProductButton id={ data.url }/>
                                </Stack>

                                <ProductUSP
                                    deliveryMessage={ ML.getField('delivery_message') }
                                    addServiceMessage={ ML.getField('addservice') }
                                    addServiceURL={ ML.getField('addservice_url') }
                                />
                            </Stack>
                        </Stack>
                    </CardContent>
                </Stack>
            </CardActionArea>
        </Card>
    );
};

export default Product;
