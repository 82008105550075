import { createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import TradeGothicHornbach from '@fonts/TradeGothicHornbach.woff2';
import BadHouseHornbachBold from '@fonts/BadHouseHornbach-Bold.woff2';

const palette = {
    primary: {
        main: '#F99D27',
        light: '#f28e001a',
        dark: '#f28e00',
        darken: '#b96000'
    },
    secondary: {
        main: '#66dc80'
    },
    checklist: {
        main: '#F7901E',
        light: '#FDC689'
    },
    grey: {
        100: '#f7eddf'
    },
    white: {
        main: '#fff',
    },
    wizard: {
        dark: '#F99D27'
    }
}

let hornbachTheme = {
    palette,
    typography: {
        ...createFontFamily('TradeGothicHornbach'),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'TradeGothicHornbach';
                  src: url('${ TradeGothicHornbach }') format('woff2');
                }
                @font-face {
                  font-family: 'TradeGothicHornbach';
                  src: url('${ BadHouseHornbachBold }') format('woff2');
                  font-weight: bold;
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '&.filter-block': {
                        paddingTop: '0!important'
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '.tooltip-icon': {
                        '.MuiSvgIcon-root': {
                            path: {
                                color: '#333'
                            }
                        }
                    },
                    '&[aria-label="pdp-go-back"]': {
                        color: '#505971'
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '.price': {
                        color: '#c40075',
                        '.MuiTypography-h5': {
                            fontWeight: 700
                        }
                    }
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPaginationItem-root.MuiButtonBase-root': {
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: 14,
                        border: `1px solid #E5E5E5`,
                        borderRadius: 2,
                        color: '#71767a',
                        background: '#fafafa',
                        '&:hover, &.Mui-selected': {
                            color: `${ palette.white.main } !important`,
                            background: `${ palette.primary.main } !important`,
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                            '&:not(.MuiPaginationItem-ellipsis)': {
                                color: `${ palette.white.main } !important`,
                                background: `${ palette.primary.main } !important`,
                                backgroundColor: `${ palette.primary.main } !important`,
                            }
                        }
                    }
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '.MuiListItem-root': {
                        fontSize: 16
                    }
                }
            }
        }
    }
}

let theme: any = createTheme(
    {
        ...BaseTheme,
        ...{
            typography: {
                fontFamily: 'TradeGothicHornbach',
                fontSize: 16,
                h4: {
                    fontWeight: 600,
                    fontSize: '2.25rem'
                },
                h6: {
                    fontSize: '1.15rem'
                }
            }
        }
    },
    hornbachTheme,
);

export default createTheme(theme, ThemeComponents(theme));
