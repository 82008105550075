import { createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import GoogleSans from '@fonts/ProductSans-Regular.woff';

let noNameTheme = {
    palette: {
        primary: {
            main: '#425563',
            light: '#e8edee',
            dark: '#a7adb2',
            darken: '#767b8d'
        },
        secondary: {
            main: '#a7adb2'
        },
        checklist: {
            main: '#425563',
            light: '#e8edee',
        },
        grey: {
            100: '#e8edee'
        }
    },
    typography: {
        ...createFontFamily('Akkurat', {
            fontSize: 18,
            h4: {
                fontSize: '2rem',
            },
            h5: {
                fontSize: '1.5rem',
            },
        }),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'GoogleSans';
                  src: url('${ GoogleSans }') format('woff');
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `
        },
    }
}

let theme: any = createTheme(BaseTheme, noNameTheme);

export default createTheme(theme, ThemeComponents(theme));
