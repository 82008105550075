import { createContext, useContext } from 'react';
import { GlobalStateContext } from './GlobalStateContext';

export const useGlobalState = (): any => useContext(GlobalStateContext);

export const CatalogContext = createContext<any>({
    isLoading: false,
    showFilters: false,
    retailerFilters: [],
    wizards: [],
    activeFilters: {},
    page: null,
    dimensions: {},
    sorting: null,
    setDimensions: ({}: any) => {},
    retailerQueryParams: {},
    totalCount: 0,
});

export const PreviewContext = createContext<any>({
    active: false,
    showControls: false,
    livePreview: false,
    isLoading: false,
    pageId: null,
});

export { PDPContext, PDPProvider } from './PDPContext';
export { SawListItemContext, SawListItemProvider } from './SawListItemContext';
export { SampleServiceContext, SampleServiceProvider } from './SampleServiceContext';
