const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_RETAILER':
            return {
                ...state,
                retailer: action.retailer
            };
        case 'SET_THEME':
            return {
                ...state,
                theme: action.theme
            };
        case 'SET_CATALOG_STATE':
            return {
                ...state,
                catalogState: action.catalogState
            };
        case 'SET_WINDOW_HEIGHT':
            parent.window.postMessage(
                JSON.stringify({
                    action: 'RESIZE',
                    height: action.height
                }),
                '*'
            );

            return state;
        default:
            return state;
    }
};

export default Reducer;
