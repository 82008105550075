import { createTheme } from '@mui/material';
import { merge } from 'lodash';

const BaseThemeOption = {
    palette: {
        text: {
            primary: '#000',
            secondary: '#3c3c3c',
            disabled: '#a8b3bf'
        },
        gray: {
            main: '#ccc',
            light: '#c4c4c4',
            lightOne: '#cfcecf',
            lightTwo: '#bbb',
            dark: '#71767a',
        },
        grey: {
            300: '#71767a'
        },
        white: {
            main: '#fff'
        },
        blue: {
            main: '#d8dadf',
            light: '#505971'
        },
        black: {
            main: '#000'
        },
        divider: '#c4c4c4',
        tonalOffset: 0.1,
        checklist: {
            main: '#6d808d',
            light: '#a1b5c5'
        },
        grayscale: {
            main: '#f8f8f8'
        },
        tile: {
            main: '#d4d2c9',
            input: '#fff',
            text: '#000',
            error: '#ee4444',
            success: '#74c200'
        },
        discount: {
            text: '#FF7300',
            label: '#FF7300'
        },
        icons: {
            usp: '#74c200'
        }
    },
    typography: {
        fontSize: 14,
        h4: {
            fontWeight: 600,
            fontSize: '2.25rem'
        },
        h6: {
            fontSize: '1.15rem'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 423,
            md: 680,
            lg: 991,
            xl: 1336,
        },
    }
};

export default createTheme(BaseThemeOption);

export const createFontFamily = (fontFamily: string, options: any = {}) => merge({
        fontFamily,
        h1: { fontFamily },
        h2: { fontFamily },
        h3: { fontFamily },
        h4: { fontFamily },
        h5: { fontFamily },
        h6: { fontFamily },
        subtitle1: { fontFamily },
        subtitle2: { fontFamily },
        body1: { fontFamily },
        body2: { fontFamily },
        button: { fontFamily },
        caption: { fontFamily },
        overline: { fontFamily },
    },
    options,
);