import { alpha, createTheme } from '@mui/material';
import ThemeInsects from './base/insects';
import {default as baseTheme } from './praxis';

let theme = createTheme(baseTheme, ThemeInsects(baseTheme));

theme = createTheme(theme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.results': {
                        [theme.breakpoints.down('md')]: {
                            flexBasis: 'unset'
                        }
                    }
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&.answer-content': {
                        '.answer-title': {
                            fontSize: 'unset',
                        }
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    '&.answer': {
                        overflow: 'hidden',
                        borderRadius: 8
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '&.answers': {
                        '.tooltip': {
                            [theme.breakpoints.down('md')]: {
                                top: 9,
                            },
                            '.tooltip-icon .MuiIconButton-root': {
                                color: theme.palette.primary.main
                            }
                        }
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.question-title': {
                        fontFamily: 'RubrikNewBold',
                    },
                    '&.question-description': {
                        p: {
                            margin: '0.5em 0'
                        }
                    }
                }
            }
        }
    }
});

export default theme;
