import React from 'react';
import { Backdrop, Paper, Typography } from '@mui/material';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { isBrowser } from '@helpers';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const TooltipDialog = ({ open, onClose, top, title, content }: any) => {
    const inIframe = () => {
        try {
            return isBrowser ? window.self !== window.top : false;
        } catch (e) { // fix for IE
            return true;
        }
    };

    return (
        <Backdrop
            open={ open }
            onClick={ onClose }
            sx={ {
                zIndex: theme => theme.zIndex.drawer + 1,
                alignItems: inIframe() ? 'flex-start' : 'center'
            } }
        >
            <Paper
                className="filter-tooltip-dialog-content"
                sx={ { transform: inIframe() ? `translateY(${ top }px)` : 'none' } }
            >
                <Typography component="h2" className="filter-tooltip-dialog-title">
                    { title }
                    <IconButton aria-label="close" className="filter-tooltip-close-btn" onClick={ onClose }>
                        <CloseIcon/>
                    </IconButton>
                </Typography>
                <Typography component="div" className="filter-tooltip-dialog-description">
                    <span>{ typeof content === 'string' ? content : renderRichText(content) }</span>
                </Typography>
            </Paper>
        </Backdrop>
    );
};

export default TooltipDialog;
